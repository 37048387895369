@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-100;
}

a.special {
  color: #800080;
  text-decoration: none;
}

a.special:hover {
  text-decoration: underline;
}